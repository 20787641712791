<template>
  <MainPanel>
    <a-card style="width: 100%; min-height: 100vh;">
      <a-layout>
        <a-layout>
          <a-layout-content :style="{ background: '#fff', minHeight: '400px' }">
            <a-row>
              <a-col :span="24">
                <a-button type="primary" @click="glm4Ask" style="width: 20%; float: right; margin-right: 45%; border-top-left-radius: 10px; border-top-right-radius: 10px;">发送</a-button>
                <a-textarea v-model:value="message" placeholder="提示词, 如: 周星驰" show-count autoSize style="width: 90%; height: 200px; font-size: 30px;" class="req" />
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="24">
                <a-button type="primary" ghost @click="()=>this.isText=!this.isText" style="width: 20%; float: right; margin-right: 45%; border-radius: 50% 20% / 10% 40%">
                  <span v-if="isText">
                    <a-space>
                      <span style="color: purple">TEXT</span>转MD阅读
                    </a-space>
                  </span>
                  <span v-else>
                    <a-space>
                      <span style="color: purple">MD</span>转TEXT阅读
                    </a-space>
                  </span>
                </a-button>
                <a-textarea v-if="isText" v-model:value="answer" placeholder="AI" show-count autoSize style="width: 90%; height: 700px; font-size: 30px;" :bordered="false" class="ai" />
                <md-editor
                        :modelValue="answer"
                        :toolbars="[]"
                        :showCodeRowNumber="true"
                        :readOnly="true"
                        theme="light"
                        style="width: 90%; height: 100%; border-radius: 10px;"
                        editorId="chat-glm4"
                        v-else
                />
              </a-col>
            </a-row>
          </a-layout-content>
        </a-layout>

        <a-layout-sider theme="light" width="400" style="margin-left: 1px; padding-left:20px;">
          <p v-if="sessionInfo === ''">未连接</p>
          <a-collapse activeKey="1" v-else>
            <a-collapse-panel key="1" header="已连接">
              <p>{{ sessionInfo }}</p>
            </a-collapse-panel>
          </a-collapse>
        </a-layout-sider>

      </a-layout>
    </a-card>
  </MainPanel>
</template>

<script>
import MainPanel from "@/components/basic/MainPanel";
import MdEditor from 'md-editor-v3';
import 'md-editor-v3/lib/style.css';
import {message} from "ant-design-vue";

export default {
  name: 'Glm4',
  components: {
    MainPanel,
    MdEditor,
  },
  data() {
    return {
      message: '',
      answer: '',

      socket: {},
      myTimer: '',

      isText: true,

      sessionInfo: '',

    }
  },

  methods: {
    glm4Ask() {
      this.answer = '';
      this.isText = true;
      if (this.sessionInfo === '') {
        message.error("未连接或已断开");
      }
      this.socket.send(this.message);
    },

    onopen() {
      console.log("---连接已建立---");
      this.myTimer = setInterval(() => {
        // ping
        this.socket.send("");
      }, 50000);
    },

    onmessage(e) {
      // console.log("---接收---", e['data']);
      let receive = e['data'];
      if (receive === '') {
        // pong
        return;
      }
      if (receive.startsWith("^_^ | ws-glm4")) {
        this.sessionInfo = receive;
      } else {
        this.answer += receive;
      }

    },

    onclose() {
      console.log("---连接关闭---");
      this.sessionInfo = '';
      clearInterval(this.myTimer);
      this.initWebSocket();
    },

    initWebSocket() {
      let token = sessionStorage.getItem("token")
      let companyId = sessionStorage.getItem("companyId")
      let roleId = sessionStorage.getItem("roleId")
      this.socket = new WebSocket("wss://dev.julycloud.cn/api/ws-glm4?authorization=" + token + "&companyId=" + companyId + "&roleId=" + roleId);
      this.socket.onopen = this.onopen;
      this.socket.onmessage = this.onmessage;
      this.socket.onclose = this.onclose;
    },


  },

  created() {
    this.initWebSocket();
  },

  mounted() {
  }

}
</script>

<style type="text/css">
  .ai .ant-input {
    /* 字体大小 */
    font-size: 20px;
    /* 内边距 */
    padding: 20px 10%;
    /* 字体颜色 */
    color: purple;
    /* 添加圆角 */
    border-radius: 10px;
    /* 添加阴影 */
    box-shadow: 0px 0px 5px #03a9f4;
    /* 使用文字阴影来增加渐变效果的深度 */
    text-shadow: 0px 0px 10px #03a9f4;
    /* 创建线性渐变背景 */
    /*background: linear-gradient(to right, #30CFD0 0%, #330867 100%);*/
    /* 字体类型 */
    /*font-family: 'STXingkai', serif;*/

    /*background: linear-gradient(90deg, pink, purple);*/
    /*-webkit-text-fill-color: transparent;*/
    /*-webkit-background-clip: text;*/

  }

  .req .ant-input {
    /* 字体大小 */
    font-size: 20px;
    /* 内边距 */
    padding: 20px 10%;
    /* 字体颜色 */
    color: blueviolet;
    /* 添加圆角 */
    border-radius: 10px;
    /* 添加阴影 */
    /*box-shadow: 0px 0px 5px #03a9f4;*/
    /* 使用文字阴影来增加渐变效果的深度 */
    /*text-shadow: 0px 0px 10px #03a9f4;*/
    /* 创建线性渐变背景 */
    /*background: linear-gradient(to left, #30CFD0 0%, #330867 100%);*/
    /* 字体类型 */
    /*font-family: 'STXingkai', serif;*/

    background-image: -webkit-linear-gradient(left, #147B96, #E6D205 25%, #147B96 50%, #E6D205 75%, #147B96);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-background-size: 200% 100%;
    -webkit-animation: maskedAnimation 4s infinite linear;

  }

  @keyframes maskedAnimation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -100% 0;
    }
  }

  /*隐藏原文档*/
  #chat-glm4 .md-editor-content .md-editor-input-wrapper {
    display: none;
  }
  /*隐藏同步滚动*/
  #chat-glm4 .md-editor-footer-right {
    display: none;
  }

  /*设置文档宽*/
  #chat-glm4 .md-editor-content {
    width: 100%;
    padding-left: 10%;
    padding-right: 10%;
  }

  /*设置目录宽*/
  #chat-glm4 .md-editor-catalog-editor {
    width: 20%;
  }

</style>
